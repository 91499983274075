import React from 'react'
import { css } from '@emotion/react'
import { StaticLayout } from '../components/StaticLayout'
import { Footer } from '../components/Footer'

const styles = css`
  body {
    padding: 0;
    margin: 0;
    /*gradient light color for faq section*/
    background-color: #fdfaf4;
  }

  #navbar {
    height: 50px;
    border-bottom: 2px solid #ccc;
    clear: both;
    background-color: #eee;
  }

  #logolink {
    float: left;
    height: 38px;
    margin-top: 7px;
    margin-left: 10px;
  }

  #logo {
    height: 38px;
  }

  #close {
    float: right;
    margin-right: 10px;
    margin-top: 7px;
    text-decoration: none;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    color: black;
    height: 40px;
    padding: 10px;
  }

  .static-content-background {
    padding: 0px 15px 15px 15px;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 1.4em;

    max-width: 700px;
    margin: auto;
  }

  h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #000066;
    cursor: default;
    font-size: 30px;
    font-weight: 500;
    margin-top: 10px;
  }

  h3 {
    font-size: 22px;
    line-height: 1.4em;
    font-weight: 500;
  }

  h5 {
    text-align: center;
    font-size: 14px;
  }
`

const PrivacyPage = () => (
  <StaticLayout>
    <div css={styles}>
      <div id="navbar">
        <a id="logolink" href="https://www.stayfari.com">
          <img
            id="logo"
            src="https://ik.imagekit.io/stayfari/general/tr:w-160,h-48,c-maintain_ratio/FL_Norwester_Elbaris_Hybrid-01_LlKpdcqksp.png?ik-sdk-version=javascript-1.4.3&updatedAt=1666155398553"
            alt="Stayfari Logo"
          />
        </a>
        <a id="close" href="javascript:window.close()">
          Close
        </a>
      </div>
      <div className="static-content-background">
        <h2>Privacy Policy</h2>
        <h5>Last updated: May 17, 2023</h5>
        <p>
          Team Stayfari understands that your privacy is very important. Here, you will find an
          update to the Privacy Policy for www.stayfari.com. It is our highest priority to ensure
          the security and confidentiality of the personal information that you provide to our Team
          as we prepare your ultimate trip experience. As a user of our website, mobile application,
          and services of Stayfari (collectively, the &quot;Stayfari Experience&quot;), we
          understand that you want to maintain control of your personal information. We are
          confident that we will earn and retain your trust, as maintaining the security of your
          personal information is a responsibility that we take very seriously.
        </p>
        <p>
          For this reason, we have provided this Privacy Policy. It explains how Team Stayfari
          collects, uses, and protects your personal information as you take part in the Stayfari
          Experience. Please read this Privacy Policy carefully and thoroughly because by using the
          Stayfari Experience, you are accepting the practices described herein.
        </p>
        <h3>
          What information does Team Stayfari Collect when I take part in the Stayfari Experience?
        </h3>
        <p>
          For Team Stayfari, your personal information means any information that can identify you,
          including but not limited to, your name, address, telephone number, e-mail address, and
          billing information. In general, we receive and store any information that you enter while
          taking part in the Stayfari Experience, or give us in any other way. Your personal
          information shared with Team Stayfari will also include any information regarding your
          trip preferences and any other information that Team Stayfari will need to prepare your
          booking. You do not have to provide us with these types of personal information, but in
          order for us to provide you with the best service, some information about you will be
          required for us to initiate any transaction through the Stayfari Experience.
        </p>
        <h4>Stayfari Registration</h4>
        <p>
          If you choose to become a registered member in order to take part in the Stayfari
          Experience, you must provide your name and email address, along with other optional
          information, including your phone number, login password and payment credentials. Team
          Stayfari may collect this information in order for us to [(i) personally identify you;
          (ii) complete your booking; (iii) contact you should any issue arise with your booking;
          (iv) service you during your trip; (v) send you updates, newsletters and notifications of
          changes to the Stayfari Experience; and (vi) improve the overall Stayfari Experience.
        </p>
        <h4>Your Profile</h4>
        <p>
          As a user of the Stayfari Experience, you may be asked to provide us with more information
          regarding your particular travel preferences, payment and billing information, special
          requests, and any other information, which may include personal information, that Team
          Stayfari may need in order to enhance your Stayfari Experience.
        </p>
        <h4>Reservation Booking</h4>
        <p>
          Once you are satisfied with your quote, you will need to provide your personal information
          for the booking. This information will help us to complete and administer your online
          reservation.
        </p>
        <h4>Superior Stayfari Customer Service</h4>
        <p>
          By taking part in the Stayfari Experience, you will have access to our superior
          international customer service that we proudly provide 24/7. You can be confident that,
          should the need arise, the Stayfari Team will be there to help. That is why it is so
          important that you share your details with our customer service team, so that we can
          respond quickly when you need us, help you with your current booking, and answer any and
          all questions you may have about your reservation.
        </p>
        <h4>Other Stayfari Communications</h4>
        <p>
          From time to time, you may receive email, traditional mail, phone calls, text messages or
          WhatsApp messages from the Stayfari Team. There could be a number of reasons for these
          communications. For example, we may need to respond to a specific request that you have
          made regarding your Stayfari Experience. If you have initiated a quote, but haven&#39;t
          completed the booking, we may send a polite reminder to finalize your booking. We
          understand that everyone has a busy schedule, so we believe that this level of service is
          useful as it will prevent you from starting your trip planning all over again. After you
          have used our services, we may send you a brief survey or ask that you write a short
          review about your interaction with the Stayfari Team, or your Stayfari Experience. For
          your added convenience, we may also send you information relating to how you can contact
          the Stayfari Team if you require our assistance while on your trip, or we may send you
          information related to your travel reservations, or we may send you information that will
          help you plan your trip and get the most out of your trip. We may also send you
          information related to your upcoming travel reservations, quick snapshots of your previous
          reservations, and inquire about potential future reservations. Finally, we may send you
          marketing updates such as newsletters and notifications of changes to the Stayfari
          Experience.
        </p>
        <p>
          When you voluntarily send us electronic mail, we will keep a record of this information so
          that we can respond to you. We only collect information from you when you register on our
          site or fill out a form. Also, when filling out a form on our site, you may be asked to
          enter your: name, e-mail address or phone number. You may, however, visit our site
          anonymously. In case you have submitted your personal information and contact details, we
          reserve the rights to Call, SMS, Email or WhatsApp about our products and offers, even if
          your number has DND activated on it.
        </p>
        <h4>Defense Against Fraud</h4>
        <p>
          It is important to Team Stayfari that we protect your information while providing superior
          service. Equally important to us is ensuring that our service is not abused or that it is
          taken advantage of to our own detriment. For this reason we may use your personal
          information to detect and prevent fraud and any other illegal or unwanted activities.
        </p>
        <h3>
          How does Team Stayfari use cookies and other similar technologies to enhance my Stayfari
          Experience?
        </h3>
        <p>
          &quot;Cookies&quot; are small data files that are stored on your computer&#39;s hard drive
          or in your mobile device&#39;s memory. Cookies allow Team Stayfari to discover new ways to
          serve you better and more efficiently. If you enable cookies on your browser, then you
          will be able to log into Stayfari.com without having to enter your login information.
          These cookies will also help us to determine how effective our service is, and how we can
          improve your Stayfari Experience.
        </p>
        <p>
          You can control how your web browser handles cookies by altering the settings that control
          how and whether cookies will be accepted. If you decide to block cookies while using the
          Stayfari Experience, then you may disable certain features that may make it impossible to
          complete a booking, or to use certain services that we provide to you. Please note that it
          is possible to block cookie activity from certain websites, while permitting cookies from
          sites you trust, like when you take part in the Stayfari Experience.
        </p>
        <h3>With whom will Team Stayfari share my personal information?</h3>
        <p>
          When you take part in the Stayfari Experience, we will provide the Third Party Provider
          whatever personal information is required for the successful fulfillment and servicing of
          your travel arrangements. It is important to note that the Stayfari Privacy Policy does
          not apply to information you share with these Third Party Providers.
        </p>
        <p>
          The Stayfari Team does not knowingly collect any personal information from minors. It is
          our policy that if we detect or become aware that a minor is attempting to submit personal
          information, we will promptly remove this information from our database and records. If
          you are a parent/guardian of a minor that you suspect may have given us personal
          information, please contact us immediately so that we may resolve the issue, and if
          necessary delete the minor&#39;s personal information.
        </p>
        <h3>How will the Stayfari team protect my personal information?</h3>
        <p>
          There are no websites on the internet that can guarantee or promise complete security.
          However, the Stayfari Team has implemented various techniques, including physical,
          technical, and administrative procedures, in order to protect the personal information you
          provide to us. Rest assured that only authorized employees are permitted to access your
          personal information, and can only do so for permitted business functions. The Stayfari
          Team is committed to using up to date data encryption methods when your personal
          information is transmitted between your computer or device and our systems. Specialized
          firewalls and intrusion detection software are used to help ensure that unauthorized
          access to your information is prevented.
        </p>
        <p>
          If you are wishing to take part of the Stayfari Experience and reside outside the United
          States, please be aware that your personal information may be transferred to, stored, and
          processed on our servers and within our central database located within the United States.
          The governing data protection and other laws of the United States and other countries may
          not be as comprehensive as the laws protecting data in your country. However, Team
          Stayfari has and will take appropriate steps to ensure that your privacy is protected. By
          using our services, you acknowledge that any personal information you provide Team
          Stayfari may be transferred to our facilities and to those Third Party Providers with whom
          we conduct business as it is described in this Privacy Policy.
        </p>
        <p>
          Thank you for visiting www.Stayfari.com, and we look forward to providing you with the
          best Stayfari Experience. Your travel awaits!
        </p>
      </div>
    </div>
    <Footer />
  </StaticLayout>
)

export default PrivacyPage
